
import { Options, Vue } from "vue-property-decorator";

import Page from "@views/page/components/page.vue";
import LogsViewFilter from "@/views/components/filter-component/logs-view-filter.vue";
import ContentLogsViewNew from "./components/content-logs-view-new.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import FilterModel from "../components/filter-model";
import getUtc from "@/filters/getUtc";

@Options<OtherActiveLogsViewPage>({
  components: {
    Page,
    LogsViewFilter,
    ContentLogsViewNew,
  },
})
export default class OtherActiveLogsViewPage extends Vue {
  pagination: PageRequest;
  filterModel: FilterModel = new FilterModel();
  filterModelView: FilterModel = null;
  getUtc = getUtc;

  onclickFilterChange() {
    this.filterModelView = JSON.parse(JSON.stringify(this.filterModel));
    this.filterModelView.OnlyActiveRequests = true;
  }

  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }
  //var date = convertUTCDateToLocalDate(new Date(date_string_you_received));
}
